<template>
  <div class="max-bottom">
    <div class="content-boxed center-text" style="padding: 10px; transform: translate3d(0,0,0); -webkit-transform: translate3d(0,0,0); -webkit-overflow-scrolling: touch;">
      <h2 class="center-text"><b>{{lang[this.appLang].link_add_p1}}</b></h2>
	    <div class="search-field half-top">
		    <i class="fa" style="padding-top: 2px;">
			    <img src="https://cdn.tapni.co/icons/loupe.png" class="fa responsive-image" style="width: 50%;">
		    </i>
		    <input v-model="search" type="text" name="search" :placeholder="lang[this.appLang].search + '...'">
		    <em></em>
	    </div>

	    <div v-if="contentLoader" class="action-block-top full-top">
		    <div class=" default-text"></div>
	    </div>
	    <div v-if="readyLinks.length > 0" v-show="!contentLoader" class="transform-z">
		    <div v-for="category in orderedCategories" :key="category" style="position: relative;">
			    <h3 v-if="filterByCategory(category).length > 0" class="center-text full-top">{{getCategoryName(category)}}</h3>
			    <LinkIcon v-for="link in filterByCategory(category)" :linkAdding="true"
			              :key="link.type" :data="link" :link-style="'link-grid'" :editing="false"
			              @click.native="toggleLinkModal(link)" @loaded="imageLoad" :disabled="forbiddenLinks.includes(link.type)"
			    />
		    </div>
	    </div>
	    <div v-else-if="!contentLoader" class="padding-left padding-right">
		    <h3 class="center-text full-top">
				{{lang[this.appLang].link_add_not_found}}
		    </h3>
		    <p class="center-text">
			    <br>
			    <br>
				{{lang[this.appLang].link_add_not_found}}
				<span v-html="lang[this.appLang].link_add_not_found_p2"></span>
			    <br>
			    <br>
				{{lang[this.appLang].link_add_not_found_p3}}
		    </p>
	    </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import LinkIcon from '../components/LinkIcon'
import Link from '../components/modals/Link'
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import {EventBus} from '../store/event-bus'

export default {
  name: 'LinkAdding',
  components: {
    Link,
    draggable,
    LinkIcon,
  },
  data () {
    return {
      search: '',
      contentLoader: true,
      counter: 0
    }
  },
  computed: {
    orderedCategories () {
      return ['featured', 'social_media', 'contact', 'finance', 'portfolio', 'music', 'business', 'uncategorized']
    },
    readyLinks () {
      return this.search === '' ?
            Object.freeze(this.linkTypes.filter(e => e.is_ready === true)) :
            Object.freeze(this.linkTypes.filter(e => e.is_ready === true && e.text.toLowerCase().includes(this.search.toLowerCase())))
    },
    forbiddenLinks (){
      return this.$store.getters.forbiddenLinks
    }
  },
  methods: {
    getCategoryName (key) {
      const formattedNames = {
        featured: this.lang[this.appLang].featured + '🔥',
        social_media: this.lang[this.appLang].social_media,
        contact: this.lang[this.appLang].contact,
        finance: this.lang[this.appLang].finance,
        portfolio: this.lang[this.appLang].portfolio,
        music: this.lang[this.appLang].music,
        business: this.lang[this.appLang].business,
        fun: this.lang[this.appLang].fun,
        crypto: this.lang[this.appLang].crypto,
        ecommerce: this.lang[this.appLang].ecommerce,
        uncategorized: this.lang[this.appLang].more,
      };
      return formattedNames[key];
    },
    filterByCategory (category) {
      return this.readyLinks.filter(e => e.category === category && e.is_ready === true)
    },
    imageLoad () {
      this.counter = this.counter + 1
      if (this.counter === this.readyLinks.length) {
        this.contentLoader = false
      }
    },
    toggleLinkModal (link) {
      if(this.forbiddenLinks.includes(link.type)) return;
      if (link.type === 'contactcard') EventBus.$emit('toggleContactCardModal', link)
      else if (link.type === 'googlereview') EventBus.$emit('toggleGoogleReviewModal', link)
      else EventBus.$emit('toggleLinkModal', link)
    },
    init () {
      if (!this.isLoggedIn) return this.$router.push('/welcome')
      this.$store.commit('setInitialize', true)
      this.$store.commit('setLoading', false)
    }
  },
  mounted () {
    if (this.route) this.init()
  },
  watch: {
    'route.name' (nv, ov) {
      if (!ov && nv) this.init()
    },
  }
}
</script>

<style scoped>
</style>
